<template>
  <li class="profile-nav onhover-dropdown pe-0 py-0">
    <div class="media profile-media">
      <div class="media-body">
        <span>{{ name }}</span>
        <p class="mb-0 font-roboto">
          {{ role }} <i class="middle fa fa-angle-down"></i>
        </p>
      </div>
    </div>
    <ul class="profile-dropdown onhover-show-div">
      <li>
        <router-link to="/admins/profile"
          ><vue-feather type="user"></vue-feather
          ><span>Profile </span></router-link
        >
      </li>
      <li>
        <router-link to="/admins/changePassword"
          ><vue-feather type="user"></vue-feather
          ><span>Change Password </span></router-link
        >
      </li>
      <li>
        <a @click="logout"
          ><vue-feather type="log-in"></vue-feather><span>Log out</span></a
        >
      </li>
    </ul>
  </li>
</template>

<script>
export default {
  name: "Profile",
  data() {
    return {
      name: null,
    };
  },
  created() {
    this.name = localStorage.getItem("f_name");
    this.role = localStorage.getItem("role");
  },
  methods: {
    logout() {
      this.$router.replace("/auth/login");
      localStorage.clear();
    },
  },
};
</script>
